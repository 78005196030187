import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "3 key insights that make D3.js easy to learn",
  "description": "The other day somebody asked me how to learn D3.js from scratch. I quipped that it took me writing a book to really learn it. It's one hell of a library.",
  "date": "2018-02-08T08:00:00.000Z",
  "published": "2018-02-08T08:00:00.000Z",
  "image": "./img/blog-wp-content-uploads-2018-02-barchartcode.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The other day somebody asked me how to learn D3.js from scratch. I quipped that it took me writing a book to really learn it. It`}{`'`}{`s one hell of a library.`}</p>
    <p>{`Most people don`}{`'`}{`t go that far. They don`}{`'`}{`t have to. Most people start with a problem, find similar examples, do some copy pasta, tweak until it works and end up with a working visualization they don`}{`'`}{`t understand. You`}{`'`}{`d be surprised how few people actually understand how their D3 dataviz works.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Anecdata: I wrote a book on it to learn it.  `}</p>
      <p parentName="blockquote">{`Normal curve is like this 👇  `}</p>
      <p parentName="blockquote">{`1) have problem/idea`}<br parentName="p"></br>{`
`}{`2) find similar example`}<br parentName="p"></br>{`
`}{`3) tweak until solve`}<br parentName="p"></br>{`
`}{`4) repeat`}<br parentName="p"></br>{`
`}{`5) get frustrated`}<br parentName="p"></br>{`
`}{`6) learn 1 or 2 basic concepts`}<br parentName="p"></br>{`
`}{`7) epiphany`}<br parentName="p"></br>{`
`}{`8) lose touch with why it felt hard at first`}<br parentName="p"></br>{`
`}{`9) teach`}</p>
      <p parentName="blockquote">{`— Swizec (@Swizec) `}<a parentName="p" {...{
          "href": "https://twitter.com/Swizec/status/961448920600604672?ref_src=twsrc%5Etfw"
        }}>{`February 8, 2018`}</a></p>
    </blockquote>
    <p>{`That was a funny tweet. It delighted 3 people, that`}{`'`}{`s my bar for success.`}</p>
    <p>{`But the idea that there`}{`'`}{`s only 1 or 2, maybe 3, key concepts you have to grok to understand every D3 example out there ... it kept me up at night. What `}<em parentName="p">{`are`}</em>{` those 3 key insights into D3?`}</p>
    <h2 {...{
      "id": "1-data-manipulation-vs-dom-manipulation"
    }}>{`1) Data manipulation vs. DOM manipulation`}</h2>
    <p>{`All D3 examples are split into two parts: Data manipulation and DOM manipulation. First you prep your values, then you render.`}</p>
    <p>{`You have to go through many examples to notice what`}{`'`}{`s going on. Inference learning is hard. Most beginners miss this pattern and it makes D3 look more confusing than it is.`}</p>
    <p>{`Let`}{`'`}{`s take an example from `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3/wiki/Gallery"
      }}>{`D3`}{`'`}{`s docs`}</a>{`, a bar chart with a hover effect.`}</p>
    <p>{`Mike Bostock, the creator of D3, built this chart in 43 lines of code. Here they are 👇`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6fbc274eed4cba9b32493cab9607ff61/0a47e/blog-wp-content-uploads-2018-02-barchartcode.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "106.27802690582959%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'425\\'%20viewBox=\\'0%200%20400%20425\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M88%2054h-2c-1-2-3%200-3%203%201%202%204%203%204%201h-1l-1-1h3l6%202c5%200%206-1%206-2%200-2%200-2%201%200%200%202%201%202%201%201h2c1%202%204%200%204-3s-1-4-3-1h-8c-1-2-3-1-3%201l-1%201v-2l-3-2c-1%200-2%200-2%202M54%20195h-2c-2-2-4-1-4%201l-1-1H35c-1-2-3%200-3%203s1%204%203%201h2c0%202%201%202%204%200h5l-1-1v-1l3%202%201-1%201-1%201%202h2c1%203%204%200%204-3s-1-4-3-1m0%2047h-2c-2-2-4-1-4%201l-1-1H36c-1-3-4%200-4%203s1%204%203%201h2c0%202%201%202%204%200h5l-1-1v-1l3%202%201-1%201-1%201%202h2c1%203%204%200%204-3s-1-4-3-1\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6fbc274eed4cba9b32493cab9607ff61/ca0a1/blog-wp-content-uploads-2018-02-barchartcode.webp 223w", "/static/6fbc274eed4cba9b32493cab9607ff61/75680/blog-wp-content-uploads-2018-02-barchartcode.webp 445w", "/static/6fbc274eed4cba9b32493cab9607ff61/e88ff/blog-wp-content-uploads-2018-02-barchartcode.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6fbc274eed4cba9b32493cab9607ff61/e92b6/blog-wp-content-uploads-2018-02-barchartcode.png 223w", "/static/6fbc274eed4cba9b32493cab9607ff61/e66bf/blog-wp-content-uploads-2018-02-barchartcode.png 445w", "/static/6fbc274eed4cba9b32493cab9607ff61/0a47e/blog-wp-content-uploads-2018-02-barchartcode.png 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/6fbc274eed4cba9b32493cab9607ff61/0a47e/blog-wp-content-uploads-2018-02-barchartcode.png",
                "alt": "Bar Chart codea",
                "title": "Bar Chart codea",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Bar Chart codea`}</figcaption>{`
  `}</figure></p>
    <p>{`There are two parts to this code: Data manipulation and DOM manipulation.`}</p>
    <p><a parentName="p" {...{
        "href": "Bar"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "600px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "106.27802690582959%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'425\\'%20viewBox=\\'0%200%20400%20425\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M54%20195h-2c-2-2-4-1-4%201l-1-1H36c-1-3-4%200-4%203s1%204%203%201h2c0%202%201%202%203%201l3-1%203%201-1-2v-1l3%202c1%201%201%201%201-1v-2l1%202c1%202%201%202%202%201h1c1%202%204%200%204-3s-1-4-3-1m0%2047h-2c-2-2-4-1-4%201h-1c-1-2-4-3-4-1h-7c-1-3-4%200-4%203s1%204%203%201h2c0%202%201%202%203%201l3-1%203%201-1-2v-1l3%202c1%201%201%201%201-1v-2l1%202c1%202%201%202%202%201h1c1%202%204%200%204-3s-1-4-3-1m5%2028h-5c-1%202-1%202-3%200H38c-2%202-2%202-2%200-1-2-3-1-4%202%200%202%203%204%204%202h1c0%202%200%202%203%201%201-1%202-1%202%201%201%201%201%201%203-1l3-1%202%201c1%200%202-1%201-2h1c2%202%202%202%202%200%201-2%201-2%201%200s2%203%202%201h1l2%201c2%200%202-1%202-3%200-4-1-5-3-2m40%2085H82l-2-1c-2%200-2%201-2%204%200%204%201%204%203%201l2-2v2c0%202%201%203%203%201%201-2%202-2%203-1%203%201%204%200%202-1v-1l2%201c0%202%202%201%202-1h1l1%202h3c1%202%203%200%203-3s-1-4-4-1\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b62f3f758e2a1b37cb436d66a54846d1/ca0a1/blog-wp-content-uploads-2018-02-barchartcode-data.webp 223w", "/static/b62f3f758e2a1b37cb436d66a54846d1/75680/blog-wp-content-uploads-2018-02-barchartcode-data.webp 445w", "/static/b62f3f758e2a1b37cb436d66a54846d1/e88ff/blog-wp-content-uploads-2018-02-barchartcode-data.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b62f3f758e2a1b37cb436d66a54846d1/e92b6/blog-wp-content-uploads-2018-02-barchartcode-data.png 223w", "/static/b62f3f758e2a1b37cb436d66a54846d1/e66bf/blog-wp-content-uploads-2018-02-barchartcode-data.png 445w", "/static/b62f3f758e2a1b37cb436d66a54846d1/0a47e/blog-wp-content-uploads-2018-02-barchartcode-data.png 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b62f3f758e2a1b37cb436d66a54846d1/0a47e/blog-wp-content-uploads-2018-02-barchartcode-data.png",
                "alt": "Bar chart data manipulation code",
                "title": "Bar chart data manipulation code",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Bar chart data manipulation code`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Bostock here first prepares his data:`}</p>
    <ul>
      <li parentName="ul">{`some sizing variables (margin, width, height)`}</li>
      <li parentName="ul">{`two scales to help with data-to-coordinates conversion (x, y)`}</li>
      <li parentName="ul">{`loads his dataset (d3.tsv) and updates his scales`}{`'`}{` domains`}</li>
      <li parentName="ul">{`uses scales to calculate attributes during DOM manipulation`}</li>
    </ul>
    <p>{`In the DOM manipulation part, he puts shapes and objects into an SVG. This is the part you then see in the browser.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/0a47e/blog-wp-content-uploads-2018-02-barchart-dom.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "106.27802690582959%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'425\\'%20viewBox=\\'0%200%20400%20425\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M88%2054h-2c-1-2-3%200-3%203%201%202%204%203%204%201h-1l-1-1h3l6%202c5%200%206-1%206-2%200-2%200-2%201%200%200%202%201%202%201%201h2c1%202%204%200%204-3s-1-4-4-1h-8c0-2-2-1-2%201l-1%201v-2l-3-2c-1%200-2%200-2%202\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/ca0a1/blog-wp-content-uploads-2018-02-barchart-dom.webp 223w", "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/75680/blog-wp-content-uploads-2018-02-barchart-dom.webp 445w", "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/e88ff/blog-wp-content-uploads-2018-02-barchart-dom.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/e92b6/blog-wp-content-uploads-2018-02-barchart-dom.png 223w", "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/e66bf/blog-wp-content-uploads-2018-02-barchart-dom.png 445w", "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/0a47e/blog-wp-content-uploads-2018-02-barchart-dom.png 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0d48cb4e05c4cbfcf2201b136ba2e6e3/0a47e/blog-wp-content-uploads-2018-02-barchart-dom.png",
                "alt": "Bar chart DOM manipulation code",
                "title": "Bar chart DOM manipulation code",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Bar chart DOM manipulation code`}</figcaption>{`
  `}</figure></p>
    <p>{`DOM manipulation in D3 happens via D3 selections. They`}{`'`}{`re a lot like jQuery `}<inlineCode parentName="p">{`$(something)`}</inlineCode>{`. Personally I like to do this part with React as described in my book, `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React+D3v4`}</a>{`.`}</p>
    <p>{`Here Bostock does a few things`}</p>
    <ul>
      <li parentName="ul">{`selects the `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` node (d3.select)`}</li>
      <li parentName="ul">{`appends a grouping `}<inlineCode parentName="li">{`<g>`}</inlineCode>{` node (.append) with an SVG positioning attribute (translate)`}</li>
      <li parentName="ul">{`adds a bottom axis by appending a `}<inlineCode parentName="li">{`<g>`}</inlineCode>{`, moving it, then calling `}<inlineCode parentName="li">{`d3.axisBottom`}</inlineCode>{` on it. D3 has built-in axis generators`}</li>
      <li parentName="ul">{`adds a left axis using the same approach but rotating the ticks`}</li>
      <li parentName="ul">{`appends a text label `}{`"`}{`Frequency`}{`"`}{` to the left axis`}</li>
      <li parentName="ul">{`uses `}<inlineCode parentName="li">{`selectAll.data`}</inlineCode>{` to make a virtual selection of `}<inlineCode parentName="li">{`.bar`}</inlineCode>{` nodes and attach some data, then for every new data value (.enter), appends a `}<inlineCode parentName="li">{`<rect>`}</inlineCode>{` node and gives it attributes`}</li>
    </ul>
    <p>{`That last part is where people get lost. It looks like magic. I`}{`'`}{`ve been using D3 for years and it still looks like magic.`}</p>
    <p>{`It`}{`'`}{`s a declarative approach to rendering data. Works great, hard to understand. That`}{`'`}{`s why I do it in React instead :)`}</p>
    <p>{`You can think of `}<inlineCode parentName="p">{`.enter`}</inlineCode>{` as a loop over your data and everything chained after `}<inlineCode parentName="p">{`.enter`}</inlineCode>{` is your loop`}{`'`}{`s body. Sort of like doing `}<inlineCode parentName="p">{`data.map(d => append(rect).setManyAttributes())`}</inlineCode></p>
    <p>{`Savvy?`}</p>
    <h2 {...{
      "id": "2-scales"
    }}>{`2) Scales`}</h2>
    <p>{`Scales are D3`}{`'`}{`s most versatile concept. They help you translate between two different spaces. Like, mathematical spaces.`}</p>
    <p>{`They`}{`'`}{`re like the mathematical functions you learned about in school. A domain maps to a range using some sort of formula.`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Function_color_example_3.svg/440px-Function_color_example_3.svg.png",
        "alt": null
      }}></img></p>
    <p>{`Colored shapes in the domain map to colors in the range. No formula for this one, which makes it an ordinal scale.`}</p>
    <pre><code parentName="pre" {...{}}>{`let shapes = d3.scaleOrdinal()
                 .domain(['red triangle', 'orange rectangle', ...)
                 .range(['red', 'orange', ...)
`}</code></pre>
    <p>{`Once you have this scale, you can use it to translate from shapes to colors. `}<inlineCode parentName="p">{`shapes('red triangle')`}</inlineCode>{` returns `}<inlineCode parentName="p">{`'red'`}</inlineCode>{` for example.`}</p>
    <p>{`Many different types of scales exist. Linear, logarithmic, quantize, etc. Any basic transformation you can think of exists. The rest you can create by writing custom scales.`}</p>
    <p>{`You`}{`'`}{`re most often going to use scales to turn your data values into coordinates. But other usecases exist.`}</p>
    <h2 {...{
      "id": "3-d3-layouts"
    }}>{`3) D3 layouts`}</h2>
    <p>{`Sure `}<inlineCode parentName="p">{`.enter.append`}</inlineCode>{` looks like magic, but D3 layouts are the real mind=blown of the D3 ecosystem. They take your input data and return a full-featured visualization thing.`}</p>
    <p>{`Like a force layout using forces between nodes to place them on the screen.`}</p>
    <p>{`Or a circle packing layout that neatly packs circles.`}</p>
    <p>{`I don`}{`'`}{`t know how these work internally. I`}{`'`}{`ve yet to try building one for others to use.`}</p>
    <p>{`But here`}{`'`}{`s a key insight about the magic of layouts: They`}{`'`}{`re the data part.`}</p>
    <p>{`You take a `}<inlineCode parentName="p">{`forceLayout`}</inlineCode>{` for example and feed it your data. It returns an object with a `}<inlineCode parentName="p">{`tick`}</inlineCode>{` event callback.`}</p>
    <pre><code parentName="pre" {...{}}>{`var simulation = d3.forceSimulation()
    .force("link", d3.forceLink().id(function(d) { return d.id; }))
    .force("charge", d3.forceManyBody())
    .force("center", d3.forceCenter(width / 2, height / 2));
`}</code></pre>
    <p>{`This `}<inlineCode parentName="p">{`simulation`}</inlineCode>{` now handles everything about rendering nodes. Changes their positions on every `}<inlineCode parentName="p">{`tick`}</inlineCode>{` callback.`}</p>
    <p>{`But it is up to you to render them. A layout handles your dataviz in the abstract. You`}{`'`}{`re still the one in control of rendering.`}</p>
    <p>{`For a force layout, you have to update the DOM on every tick of the animation. For circle packing, you render it once.`}</p>
    <p>{`It took me a while to get this and once I did, all the fancy looking visualizations started to make sense.`}</p>
    <h2 {...{
      "id": "fin"
    }}>{`Fin`}</h2>
    <p>{`To summarize, you need to grok 3 key insights for D3 to make sense. Once they click, a whole new world opens up.`}</p>
    <ol>
      <li parentName="ol">{`Code is split into data and DOM manipulation`}</li>
      <li parentName="ol">{`Scales are great and used a lot`}</li>
      <li parentName="ol">{`You`}{`'`}{`re always in control of rendering`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      